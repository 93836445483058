<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <b-overlay :show="loadit">
          <b-alert show dismissible variant="warning">
            <b>{{ $t('li_step.this_bin_number_has_been_allready_inspaction') }}</b>
          </b-alert>
          <b-table-simple bordered>
            <b-tr>
              <th style="width:10%">{{ $t('globalTrans.sl_no') }}</th>
              <th style="width: 30%">{{ $t('li_step.application_id') }}</th>
              <th style="width: 30%">{{ $t('li_step.bin_no') }}</th>
              <th style="width: 30%">{{ $t('globalTrans.inspaction_date') }}</th>
              <th style="width: 30%">{{ $t('globalTrans.action') }}</th>
            </b-tr>
            <tr v-for="(item, index) in inspaction" :key="index">
              <b-td>
                {{ $n(index + 1) }}
              </b-td>
              <b-td>{{ item.application_id }}</b-td>
              <b-td>{{ item.file_no }}</b-td>
              <b-td>
                <slot v-if="item.isnpaction">
                  {{ item.created_at | dateFormat }}
                  ({{ dateTimeCalculation(item.created_at) }})
                </slot>
              </b-td>
              <b-td>
                <a v-if="item.isnpaction" href="javascript:" class="btn_table_action table_action_view" title="View Inspection" v-b-modal.modal-5 @click="details(item)"><i class="fas fa-eye"></i></a>
              </b-td>
            </tr>
          </b-table-simple>
          <div class="row">
            <div class="col-8 offset-4" sm="12">
                <div class="text-right">
                    <span style="font-size: 16px;font-weight:bold;margin-right: 10px">{{ $t('li_step.are_you_send_to_inspaction') }}</span>
                    <b-button @click="childMethod" type="button" variant="primary" class="mr-2">{{ $t('globalTrans.yes') }}</b-button>
                    &nbsp;
                    <b-button variant="danger" @click="$bvModal.hide('modal-4')" class="mr-1">{{ $t('globalTrans.no') }}</b-button>
                </div>
            </div>
          </div>
        </b-overlay>
      </b-col>
    </b-row>
    <b-modal ref="mymodal" id="modal-5" size="xl" :title="$t('pump_install.inspection')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <p>
        <b-overlay :show="loading">
          <DaeInspection v-if="paramsData.service_id === 5" :forward="true" :isAdmin="true" :ActionData="ActionData" :key="appKey"/>
          <ProductionInspection v-if="paramsData.service_id === 6" :forward="true" :isAdmin="true" :ActionData="ActionData" :key="appKey"/>
          <DaeDistributorInspection v-if="paramsData.service_id === 23" :forward="true" :isAdmin="true" :ActionData="ActionData" :key="appKey"/>
        </b-overlay>
      </p>
    </b-modal>
  </b-container>
</template>
<script>
import DaeInspection from '@/components/license/dae/details/Inspection'
import ProductionInspection from '@/components/license/dae/details/ProductionInspection'
import DaeDistributorInspection from '@/components/license/dae/details/DaeDistributorInspection'
import RestApi, { licenseRegistrationServiceBaseUrl } from '@/config/api_config'
import { isNotEmpty } from '@amcharts/amcharts4/.internal/core/utils/Utils'
import { stepFormDetailsData } from '../../api/routes'
import moment from 'moment'

export default {
  components: {
    DaeInspection,
    ProductionInspection,
    DaeDistributorInspection
  },
  props: ['inspaction'],
  data () {
    return {
      loadit: false,
      designationLoading: false,
      officerLoading: false,
      ActionData: [],
      appKey: 0,
      paramsData: [],
      saveBtnName: this.$t('globalTrans.send')
    }
  },
  created () {
  },
  methods: {
    childMethod () {
      this.loadit = true
      this.$emit('child-method', 0)
      this.$refs.mymodal.hide()
    },
    dateTimeCalculation (dateTime) {
      const today = new Date().toISOString().substr(0, 10)
      const start = moment(today, 'YYYY-MM-DD')
      const end = moment(dateTime, 'YYYY-MM-DD')
      const days = start.diff(end, 'days')
      return this.$n(days) + ' ' + this.$t('li_step.day_ago')
    },
    async loadActionFrom (item) {
      let id = 0
      let step = 0
      if (item.service_id === 5) {
        id = 41
        step = 68
      } else if (item.service_id === 6) {
        id = 40
        step = 53
      } else {
        id = 29
        step = 62
      }
      const params = {
        id: id,
        service_id: item.service_id,
        step_id: step,
        applicationID: item.application_id,
        org_id: 2
      }
      this.paramsData = params
      await RestApi.getData(licenseRegistrationServiceBaseUrl, stepFormDetailsData, params).then(response => {
        if (isNotEmpty(response.data)) {
          this.loading = false
          this.ActionData = response.data
        } else {
          this.loading = false
        }
      })
    },
    details (item) {
      this.loading = true
      this.loadActionFrom(item)
    }
  }
}
</script>
