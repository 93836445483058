<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('bsri_demandDIS.forward') }}</h4>
          </template>
          <template v-slot:body>
            <b-overlay :show="loading">
              <b-row>
                <b-col lg="12" sm="12" md="12">
                  <b-form-group
                    class="row"
                    label-cols-sm="2"
                    label-for="application_id"
                  >
                    <template v-slot:label>
                      {{ $t('li_step.application_id') }} <span class="text-danger">*</span>
                    </template>
                    <div v-for="(item, index) in stepChange.application_id" :key="index" class="d-inline mr-2">
                      <span  class="badge badge-success">{{ item }}</span>
                    </div>
                  </b-form-group>
                </b-col>
                <b-col lg="6" sm="6" md="6">
                  <ValidationProvider name="Step Name" vid="step_id" rules="required|min_value:1">
                    <b-form-group
                      class="row"
                      label-cols-sm="3"
                      label-for="step_id"
                      slot-scope="{ valid, errors }"
                      >
                      <template v-slot:label>
                      {{ $t('step_name.step_name') }} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                        plain
                        v-model="form.next_step_id"
                        :options="stepList"
                        id="step_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                        >
                        <template v-slot:first>
                          <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                      </b-form-select>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col lg="12" sm="12">
                  <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                    <b-form @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset">
                      <b-row>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Organization" rules="required|min_value:1">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="3"
                                  label-for="org_id"
                                  slot-scope="{ valid, errors }"
                                  >
                                  <template v-slot:label>
                                  {{ $t('org_pro.organization')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-select
                                  plain
                                  v-model="form.org_id"
                                  :options="orgList"
                                  id="org_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  :disabled="orgDisabledCheck()"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Office Type">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="3"
                                  label-for="office_type_id"
                                  slot-scope="{ valid, errors }"
                                  >
                                  <template v-slot:label>
                                  {{ $t('org_pro.office_type')}}
                                  </template>
                                  <b-form-select
                                  plain
                                  v-model="form.office_type_id"
                                  :options="officeTypeList"
                                  id="office_type_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  :disabled="disabledCheck()"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Office Id" vid="office_id" rules="required|min_value:1">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="3"
                                  label-for="office_id"
                                  slot-scope="{ valid, errors }"
                                  >
                                  <template v-slot:label>
                                  {{ $t('globalTrans.office')}}
                                  </template>
                                  <v-select name="office_id"
                                    v-model="form.office_id"
                                    label="text"
                                    :reduce="item => item.value"
                                    :options= officeList
                                    :placeholder="$t('globalTrans.select')"
                                    :filter-by="myFilter"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    :disabled="officeDisabledCheck()"
                                  />
                                  <div class="invalid-feedback d-block">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Designation Id" vid="designation_id" rules="required|min_value:1">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="3"
                                  label-for="designation_id"
                                  slot-scope="{ valid, errors }"
                                  >
                                  <template v-slot:label>
                                  {{ $t('user_role.designation')}}
                                  </template>
                                  <b-form-select
                                  plain
                                  v-model="form.designation_id"
                                  :options="designationList"
                                  id="designation_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  :disabled="officeDisabledCheck()"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{ designationLoading ? 'Loading..' : $t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Officer" vid="receiver_id" rules="required|min_value:1">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="3"
                                  label-for="receiver_id"
                                  slot-scope="{ valid, errors }"
                                  >
                                  <template v-slot:label>
                                  {{ $t('budget.officer')}}
                                  </template>
                                  <b-form-select
                                  plain
                                  v-model="form.receiver_id"
                                  :options="officerList"
                                  id="receiver_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  :disabled="officeDisabledCheck()"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{ officerLoading ? 'Loading..' : $t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12"></b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Select Note" vid="note_id">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="3"
                                  label-for="note_id"
                                  >
                                  <template v-slot:label>
                                  {{ $t('li_step.select_note')}}
                                  </template>
                                  <b-form-select
                                  plain
                                  v-model="form.note_id"
                                  :options="noteList"
                                  id="note_id"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{ $t('globalTrans.select') }}</b-form-select-option>
                                  </template>
                                  </b-form-select>
                              </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12"></b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Note" vid="note">
                            <b-form-group
                                class="row"
                                label-cols-sm="3"
                                label-for="note"
                            >
                                <template v-slot:label>
                                    {{ $t('li_step.note_en') }}
                                </template>
                                <b-form-textarea
                                    plain
                                    v-model="form.note_en"
                                    id="note"
                                >
                                </b-form-textarea>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Note" vid="note">
                            <b-form-group
                                class="row"
                                label-cols-sm="3"
                                label-for="note"
                                slot-scope="{ valid, errors }"
                            >
                                <template v-slot:label>
                                    {{ $t('li_step.note_bn') }}
                                </template>
                                <b-form-textarea
                                    plain
                                    v-model="form.note_bn"
                                    id="note"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                >
                                </b-form-textarea>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="attachment" vid="attachment" :rules="stepChange.step_id === 35 ? 'required' : (actions(stepChange.service_id).is_doc_required ? 'required' : '')">
                            <b-form-group
                              class="row"
                              label-cols-sm="3"
                              label-for="attachment"
                              slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                              <slot v-if="stepChange.step_id === 35">{{ $t('globalTrans.committee') }}  {{ $t('globalTrans.attachment') }}  <span class="text-danger">*</span></slot><slot v-else>{{ $t('globalTrans.attachment') }}</slot>
                              </template>
                              <b-form-file
                                id="attachment"
                                v-model="form.attachment"
                                v-on:change="onFileChange"
                                :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-file>
                              <div class="invalid-feedback">
                              {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col md='12' lg="12" v-if='actions(stepChange.service_id).is_doc_required'>
                          {{ $t('globalTrans.note') + ' : ' }} {{ $t('li_step.forward_message') }}
                        </b-col>
                      </b-row>
                      <div class="row">
                        <div class="col-8 offset-4" sm="12">
                            <div class="text-right">
                                <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                                &nbsp;
                                <b-button variant="danger" class="mr-1" @click="back()">{{ $t('globalTrans.cancel') }}</b-button>
                            </div>
                        </div>
                      </div>
                    </b-form>
                  </ValidationObserver>
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
      {{ querys }}
    </b-row>
    <b-modal ref="mymodal" id="modal-4" size="lg" :title="$t('li_step.inspaction')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <Inspaction v-on:child-method="updateParent" :inspaction="inspaction"/>
    </b-modal>
  </b-container>
</template>
<style>
.toast-warning .toast-message {
  color: #fff !important;
}
.toast-warning .toast-title {
  color: #fff !important;
}
</style>
<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { licenseRegistrationServiceBaseUrl, commonServiceBaseUrl } from '@/config/api_config'
import { forwardStore, designationWiseUser } from '../../api/routes'
import Inspaction from './Inspaction'

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    Inspaction
  },
  props: ['stepChange'],
  data () {
    return {
      loading: false,
      designationLoading: false,
      officerLoading: false,
      saveBtnName: this.$t('globalTrans.send'),
      form: {
        org_id: 0,
        office_type_id: 0,
        office_id: null,
        designation_id: 0,
        note_en: '',
        note_bn: '',
        application_id: [],
        inspaction_need: true,
        service_id: 0,
        step_id: 0,
        next_step_id: 0,
        sender_id: this.$store.state.Auth.authUser.user_id,
        org_admin: this.$store.state.Auth.authUser.is_org_admin,
        receiver_id: 0,
        status: 0,
        note_id: 0,
        attachment: ''
      },
      previousRouteName: '',
      previousRouteParams: '',
      officeTypeList: [],
      officeList: [],
      designationList: [],
      officerList: [],
      stepList: [],
      assignDesignationList: [],
      attachmentDemo: [],
      daeOfficeTypeDisalbed: false,
      formView: false,
      inspaction: [],
      myFilter: (option, text, search) => {
        const temp = search.toLowerCase()
        return option.text_en.toLowerCase().indexOf(temp) > -1 ||
        option.text_bn.toLowerCase().indexOf(temp) > -1
      }
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.previousRouteName = from.name
      vm.previousRouteParams = from.params || {}
    })
  },
  beforeRouteUpdate (to, from, next) {
    this.previousRouteName = from.name
    this.previousRouteParams = from.params || {}
    next()
  },
  created () {
    this.getAssignDesignationList()
    this.form.application_id = this.stepChange.application_id
    if (this.stepChange.step_id) {
      this.form.step_id = this.stepChange.step_id
      this.form.next_step_id = this.stepChange.step_id
    }
    if (this.stepChange.service_id) {
      this.form.service_id = this.stepChange.service_id
      this.stepList = this.getServiceWiseStep(this.form.service_id)
    }
    if (this.stepChange.fromdetails) {
      this.formView = this.stepChange.fromdetails
    }
    this.setOrgAndOffice()
  },
  watch: {
    $route: function (to, from) {
      this.lastUrl = from.fullPath
    },
    'form.org_id': function (newVal, oldVal) {
      if (oldVal !== newVal) {
        this.officeTypeList = this.getOfficeTypeList(newVal)
      }
    },
    'form.office_type_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.officeList = this.getParentOfficeList(newVal)
      }
    },
    'form.office_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getDesignationList(newVal)
      }
    },
    'form.designation_id': function (newVal) {
      this.getOfficerList(newVal)
    },
    'form.next_step_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.form.next_step_id = newVal
        if ([69, 54, 63, 83, 86, 89].includes(newVal)) {
          this.daeOfficeTypeDisalbed = false
          this.daeOfficeDisalbed = false
          this.form.office_type_id = 0
          this.form.office_id = 0
          this.form.designation_id = 0
          this.form.receiver_id = 0
        }
        if (newVal === 69 || newVal === 54 || newVal === 63) {
          this.form.office_type_id = 105
          this.form.office_id = 0
          this.form.designation_id = 0
          this.form.receiver_id = 0
          this.daeOfficeTypeDisalbed = true
          this.daeOfficeDisalbed = false
        } else if (newVal === 83 || newVal === 86 || newVal === 89) {
          this.inspectionOfficeChangeFinal()
        } else {
          this.daeOfficeTypeDisalbed = false
          this.daeOfficeDisalbed = false
        }
        this.$refs.form.reset()
      }
    },
    'form.note_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.setNote(newVal)
      }
    }
  },
  computed: {
    orgList: function () {
      let componentOrgList = this.$store.state.orgComponentList.filter(item => item.component_id === 2)
      if (this.form.service_id === 17) {
        componentOrgList = componentOrgList.filter(componentOrgList => componentOrgList.org_id === 1 || componentOrgList.org_id === 14)
      }
      const loadingOrg = componentOrgList.map(item => {
        const org = this.$store.state.orgList.find(orgItem => orgItem.value === item.org_id)
          if (this.$i18n.locale === 'bn') {
            return { text: org.text_bn, value: org.value }
          } else {
            return { text: org.text_en, value: org.value }
          }
      })
      return loadingOrg
    },
    noteList: function () {
      return this.$store.state.licenseRegistration.commonObj.orgNotesList.filter(item => item.status === 1)
    }
  },
  methods: {
    updateParent (data) {
      this.form.inspaction_need = data
      this.saveData()
    },
    inspectionOfficeChangeFinal () {
      this.form.office_type_id = 88
      this.form.office_id = 216
      this.form.designation_id = 59
      this.form.receiver_id = 522
      this.daeOfficeDisalbed = true
      this.daeOfficeTypeDisalbed = true
    },
    orgDisabledCheck () {
      if (this.form.service_id === 17) {
        return false
      } else if (this.$store.state.Auth.activeRoleId === 1) {
        return false
      }
      return true
    },
    disabledCheck () {
      if (this.form.service_id === 17) {
        return false
      } else if (this.daeOfficeTypeDisalbed) {
        return true
      } else if (this.form.service_id === 17 || this.form.org_id === 2) {
        return false
      } else if (this.form.org_id === 3 && (this.stepChange.service_id === 3 || this.stepChange.service_id === 10)) {
        return false
      } else {
        if (this.$store.state.Auth.activeRoleId !== 1 && this.$store.state.Auth.authUser.is_org_admin !== 1) {
          return true
        }
      }
      return false
    },
    officeDisabledCheck () {
      if (this.daeOfficeDisalbed) {
        return true
      }
      return false
    },
    officeDisabled () {
      if (this.form.org_id === 2) {
        return true
      }
    },
    disabledCheckAll () {
      if (this.form.org_id === 3 && (this.stepChange.service_id === 3 || this.stepChange.service_id === 10)) {
        return false
      } else {
        if (this.$store.state.Auth.activeRoleId !== 1 && this.$store.state.Auth.authUser.is_org_admin !== 1) {
          return true
        }
      }
      return false
    },
    actions (serviceId) {
      const actions = this.$store.state.licenseRegistration.commonObj.seviceStepWiseButtonList.find(item => item.step_id === this.stepChange.step_id && item.service_id === serviceId)
      if (typeof actions === 'undefined') {
        return {}
      } else {
        return actions
      }
    },
    onFileChange (e) {
      this.getBase64(e.target.files[0]).then(res => {
        this.attachmentDemo = res
      })
    },
    getBase64 (file) {
      return new Promise(function (resolve, reject) {
        const reader = new FileReader()
        let imgResult = ''
        reader.readAsDataURL(file)
        reader.onload = function () {
            imgResult = reader.result
        }
        reader.onerror = function (error) {
            reject(error)
        }
        reader.onloadend = function () {
            resolve(imgResult)
        }
      })
    },
    setNote (noteId) {
      const note = this.noteList.find(item => item.value === noteId)
      if (note !== undefined) {
        this.form.note_en = note.text_en
        this.form.note_bn = note.text_bn
      }
    },
    setOrgAndOffice () {
      const ownorg = this.$store.state.Auth.authUser.org_id
      const ownofficetype = this.$store.state.Auth.authUser.office_type_id
      const ownoffice = this.$store.state.Auth.authUser.office_id
      this.form.org_id = ownorg
      this.form.office_type_id = ownofficetype
      const isOfficeTheare = this.$store.state.commonObj.officeList.find(item => parseInt(item.value) === ownoffice)
      if (typeof isOfficeTheare !== 'undefined') {
        this.form.office_id = ownoffice
      } else {
        this.form.office_id = null
      }
    },
    getServiceWiseStep (serviceId) {
      let tmpStepList = this.$store.state.licenseRegistration.commonObj.serviceSteps.filter(item => item.service_id === parseInt(serviceId) && item.order !== 1)
        tmpStepList = tmpStepList.filter(item => item.status === 1)
      if (this.form.step_id === 68) {
        tmpStepList = tmpStepList.filter(item => item.step_id === 69 || item.step_id === 83)
      } else if (this.form.step_id === 53) {
        tmpStepList = tmpStepList.filter(item => item.step_id === 54 || item.step_id === 86)
      } else if (this.form.step_id === 62) {
        tmpStepList = tmpStepList.filter(item => item.step_id === 63 || item.step_id === 89)
      }
      const stepList = tmpStepList.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { text: item.step_name_bn, value: item.step_id, order_id: item.order }
        } else {
          return { text: item.step_name, value: item.step_id, order_id: item.order }
        }
      })
      return stepList
    },
    getOfficeTypeList (orgId = null) {
      if (!orgId) {
        return []
      }
      let officeTypeList = this.$store.state.commonObj.officeTypeList.filter(item => item.status === 0)
      if (orgId) {
          if (this.form.org_id === 3 && (this.stepChange.service_id === 3 || this.stepChange.service_id === 10)) {
            officeTypeList = this.$store.state.commonObj.officeTypeList.filter(item => item.value === 80 || item.value === 4)
          }
          return officeTypeList.filter(office => office.org_id === orgId).map(obj => {
              if (this.$i18n.locale === 'bn') {
                  return { value: obj.value, text: obj.text_bn }
              } else {
                  return { value: obj.value, text: obj.text }
              }
          })
      }
      return officeTypeList
    },
    async saveData () {
        this.loading = true
        this.$store.dispatch('mutateCommonProperties', { loading: true })
        let result = null
        const loadingState = { loading: false, listReload: false }
        const config = { headers: { 'content-type': 'multipart/form-data' } }
        var formData = new FormData()
        Object.keys(this.form).map(key => {
            if (key === 'attachment') {
            formData.append(key, this.attachmentDemo)
            } else if (key === 'application_id') {
              this.form[key].forEach((item) => {
                formData.append('application_id[]', item)
              })
            } else {
              formData.append(key, this.form[key])
            }
        })
        result = await RestApi.postData(licenseRegistrationServiceBaseUrl, forwardStore, formData, config)
        this.loading = false
        loadingState.listReload = true
        this.$store.dispatch('mutateCommonProperties', loadingState)
        if (result.success) {
            this.$store.commit('licenseRegistration/mutateLicenseRegistrationProperties', { hasDropdownLoaded: false })
            this.$toast.success({
                title: this.$t('globalTrans.success'),
                message: this.$t('globalTrans.save_msg'),
                color: '#D6E09B'
            })
            if (this.formView === true) {
              this.$router.go(-2)
            } else {
              this.stepChange.searchStore.totalRows = this.stepChange.searchStore.totalRows - 1
              const queryParams = {
                searchStore: encodeURIComponent(JSON.stringify(this.stepChange.searchStore))
              }
              this.$router.push({ name: this.previousRouteName, params: this.previousRouteParams, query: queryParams })
            }
        } else {
          if (result.inspacton) {
            this.$refs.mymodal.show()
          }
          this.inspaction = result.data
          if (this.inspaction.length > 0) {
            this.$toast.warn({
              title: 'Warning',
              message: this.$t(result.message)
            })
          } else {
            this.$toast.error({
              title: this.$t('globalTrans.error'),
              message: this.$t(result.message)
            })
          }
          // this.$refs.form.setErrors(result.errors)
        }
    },
    getParentOfficeList (officeTypeId = null) {
      if (!officeTypeId) {
        return []
      }
      const officeList = this.$store.state.commonObj.officeList.filter(item => item.status === 0)
      if (officeTypeId) {
        if (this.form.org_id === 3 && (this.stepChange.service_id === 3 || this.stepChange.service_id === 10)) {
          const officeListOwn = this.$store.state.commonObj.officeList.find(officeList => officeList.value === this.$store.state.Auth.authUser.office_id)
          if (officeListOwn.area_type_id === 1) {
            return officeList.filter(office => office.office_type_id === officeTypeId && office.city_corporation_id === officeListOwn.city_corporation_id)
          } else {
            return officeList.filter(office => office.office_type_id === officeTypeId && office.upazilla_id === officeListOwn.upazilla_id)
          }
        } else {
          return officeList.filter(office => office.office_type_id === officeTypeId)
        }
      }
      return officeList
    },
    async getAssignDesignationList () {
      this.designationLoading = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      const result = await RestApi.getData(commonServiceBaseUrl, 'assign-designation/list-all')
      if (result.success) {
        this.assignDesignationList = result.data
      } else {
        this.assignDesignationList = []
      }
      this.designationLoading = false
      this.getDesignationList(this.form.office_id)
      this.$store.dispatch('mutateCommonProperties', { loading: false })
    },
    getDesignationList (officeId) {
      const assignDesignationList = this.assignDesignationList.filter(item => item.office_id === officeId && item.org_id === this.form.org_id && item.office_type_id === this.form.office_type_id)
      this.designationList = assignDesignationList.map(item => {
        const designation = this.$store.state.commonObj.designationList.find(obj => obj.value === item.designation_id)
        return designation
      })
    },
    async getOfficerList (designationId) {
      if (!designationId) {
        return []
      }
      this.officerLoading = true
      const params = {
        org_id: parseInt(this.form.org_id),
        office_type_id: parseInt(this.form.office_type_id),
        office_id: parseInt(this.form.office_id),
        designation_id: parseInt(this.form.designation_id)
      }
      await RestApi.getData(licenseRegistrationServiceBaseUrl, designationWiseUser, params)
      .then(response => {
          if (response.success) {
            const data = response.data
            this.officerList = data.map(item => {
              return Object.assign({ value: item.user_id, text: this.$i18n.locale === 'bn' ? item.name_bn : item.name })
            })
          } else {
            this.officerList = []
          }
          this.officerLoading = false
      })
    }
  }
}
</script>
